exports.components = {
  "component---src-page-components-contact-tsx": () => import("./../../../src/pageComponents/Contact.tsx" /* webpackChunkName: "component---src-page-components-contact-tsx" */),
  "component---src-page-components-team-member-tsx": () => import("./../../../src/pageComponents/TeamMember.tsx" /* webpackChunkName: "component---src-page-components-team-member-tsx" */),
  "component---src-page-components-team-tsx": () => import("./../../../src/pageComponents/team.tsx" /* webpackChunkName: "component---src-page-components-team-tsx" */),
  "component---src-page-components-video-tsx": () => import("./../../../src/pageComponents/Video.tsx" /* webpackChunkName: "component---src-page-components-video-tsx" */),
  "component---src-pages-clinics-tsx": () => import("./../../../src/pages/clinics.tsx" /* webpackChunkName: "component---src-pages-clinics-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-service-tsx": () => import("./../../../src/pages/Service.tsx" /* webpackChunkName: "component---src-pages-service-tsx" */)
}

